"use client";
import { useState, useEffect, useRef } from "react";

const Counter = ({ backgroundImage, counters, duration = 2000 }) => {
  const [isVisible, setIsVisible] = useState(false);
  const [counts, setCounts] = useState(
    counters.map((counter) => counter.startingValue || 0)
  );
  const counterRef = useRef(null);

  useEffect(() => {
    const observer = new IntersectionObserver(
      (entries) => {
        const entry = entries[0];
        if (entry.isIntersecting) {
          setIsVisible(true);
          observer.disconnect();
        }
      },
      { threshold: 0.1 }
    );

    if (counterRef.current) {
      observer.observe(counterRef.current);
    }

    return () => {
      if (counterRef.current) {
        observer.unobserve(counterRef.current);
      }
    };
  }, []);

  useEffect(() => {
    if (isVisible) {
      counters.forEach((counter, index) => {
        const { startingValue, endValue, steps } = counter;
        let currentCount = startingValue;

        const incrementTime = Math.abs(
          Math.floor(duration / Math.ceil((endValue - startingValue) / steps))
        );

        const timer = setInterval(() => {
          currentCount += steps;
          if (currentCount >= endValue) {
            currentCount = endValue;
            clearInterval(timer);
          }

          setCounts((prevCounts) => {
            const newCounts = [...prevCounts];
            newCounts[index] = currentCount;
            return newCounts;
          });
        }, incrementTime);
      });
    }
  }, [isVisible, counters, duration]);

  return (
    <div
      className="orange-tint py-8 md:py-16 lg:py-16 -z-20 before:-z-10 bg-right"
      style={{
        backgroundImage: `url(${backgroundImage})`,
        backgroundPosition: "right",
      }}
      ref={counterRef}
    >
      {isVisible && (
        <div className="max-w-screen-xl mx-5 lg:mx-auto">
          <div className="grid grid-cols-1 md:grid-cols-3 gap-3 md:gap-0 items-center justify-between text-white text-center">
            {counters.map((counter, index) => (
              <div key={index}>
                <div className="text-3xl md:text-5xl lg:text-6xl font-bold">
                  {counter.prefix ? counter.prefix : ""}
                  {counts[index]}
                </div>
                <p className="font-bold text-base md:text-lg">
                  {counter.label}
                </p>
              </div>
            ))}
          </div>
        </div>
      )}
    </div>
  );
};

export default Counter;
