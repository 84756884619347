"use client";
import { useState, useEffect } from "react";
import Image from "next/image";
import Link from "next/link";

const Instagram = () => {
  return (
    <div>
      <section className="bg-orange-100 py-10 md:py-20 lg:py-24 relative">
        <div className="max-w-screen-xl mx-5 lg:mx-auto">
          <div>
            <div
              className="font-extrabold text-orange-100 uppercase text-6xl lg:text-[12rem] relative -top-5 md:-top-20 z-10 text-center md:text-left"
              style={{ textShadow: "0px 0px 5px rgba(0,0,0,0.3)" }}
            >
              Instagram
            </div>

            <div className="font-alternate text-orange-500 text-2xl md:text-3xl lg:text-6xl text-center relative -top-16 md:-top-48 z-20">
              Follow Us On
            </div>
            <div className="mt-6 md:-mt-28 pb-14 text-center">
              <Image
                src="/images/insta-profile.png"
                className="object-contain mx-auto"
                width={400}
                height={200}
                alt="Instagram Profile"
              />
            </div>
          </div>

          <div className="grid grid-cols-2 md:grid-cols-4 gap-3 items-center justify-between">
            <div>
              <Image
                src="/images/insta-1.png"
                className="object-cover w-full"
                width={300}
                height={300}
                alt="Instagram Image 1"
              />
            </div>
            <div>
              <Image
                src="/images/insta-2.png"
                className="object-cover w-full"
                width={300}
                height={300}
                alt="Instagram Image 2"
              />
            </div>
            <div>
              <Image
                src="/images/insta-3.png"
                className="object-cover w-full"
                width={300}
                height={300}
                alt="Instagram Image 3"
              />
            </div>
            <div>
              <Image
                src="/images/insta-4.png"
                className="object-cover w-full"
                width={300}
                height={300}
                alt="Instagram Image 4"
              />
            </div>
          </div>

          <div className="flex flex-col items-center justify-center mt-10">
            <Link href="https://www.instagram.com/rajivchell/">
              <p className="hover:bg-orange-600 border border-orange-600 bg-orange-500 text-white hover:text-white font-semibold rounded-md uppercase px-6 py-2 lg:px-8 lg:py-2 text-sm w-48 text-center">
                Follow Us
              </p>
            </Link>
          </div>
        </div>
      </section>
    </div>
  );
};

export default Instagram;
