"use client";
import { useState, useEffect } from "react";
import Image from "next/image";
import { Button } from "flowbite-react";

const Slider = ({ videoSrc, title, subtitle, description }) => {
  return (
    <section className="-mt-44 md:-mt-2">
      <div className="text-center">
        <div className="relative w-full h-screen overflow-hidden">
          {/* Section Title and Description */}
          <div className="absolute z-10 max-w-screen-lg px-4 md:pl-36 text-left w-full h-full flex flex-col justify-end text-white pb-7">
            <div className="font-alternate text-orange-500 items-start text-3xl mb-10 md:text-5xl lg:text-8xl">
              {title}
            </div>
            <h1 className="font-semibold font-sans text-3xl md:text-5xl lg:text-5xl uppercase leading-snug md:leading-tight lg:leading-tight">
              {subtitle}
            </h1>

            <div
              className="font-sans font-bold text-white text-xl md:text-2xl lg:text-3xl text-shad-wh"
              style={{
                lineHeight: "1.3",
                fontFamily2: "ui-serif, Georgia, Cambria, Times, serif",
              }}
            >
              <span className="text-outline-2 tracking-wide text-transparent">
                {description}
              </span>
            </div>
          </div>
          {/* Video container */}
          <video
            autoPlay
            loop
            muted
            playsInline
            className="absolute z-0 w-96 md:w-auto min-w-full min-h-full max-w-none bg-black"
          >
            <source src={videoSrc} type="video/mp4" />
          </video>
        </div>
      </div>
    </section>
  );
};

export default Slider;
