"use client";
import { useState, useEffect } from "react";
import Image from "next/image";
import { Avatar, Blockquote } from "flowbite-react";
import Link from "next/link";

const Testimonials = () => {
  return (
    <div
      className="black-tint py-8 md:py-16 lg:py-28 -z-20 before:-z-10"
      style={{ backgroundImage: 'url("/images/testimonials_bg.jpg")' }}
    >
      <div className="max-w-screen-xl mx-5 lg:mx-auto">
        <div className="mb-6 md:mb-10 lg:mb-16 text-center">
          <div className="font-alternate text-orange-500 text-2xl md:text-3xl lg:text-5xl">
            Testimonials
          </div>
          <h2 className=" font-sans text-4xl font-bold text-white uppercase my-3">
            Customer Reviews
          </h2>
        </div>
        <div className="grid grid-cols-1 md:grid-cols-3 gap-3 md:gap-6 lg:gap-20 items-start justify-between text-white text-center">
          <div>
            <figure className="mx-auto max-w-screen-md text-center">
              <Blockquote>
                <p className="ttext-base md:text-lg font-medium italic text-gray-300">
                  "A Big thank you to you for conducting an excellent session at
                  L.E.A.D 3. All participants were very appreciative of the
                  content you delivered. Great Job. Congrats to you."
                </p>
              </Blockquote>
              <figcaption className="mt-6 flex items-center justify-center space-x-3">
                {/* <Avatar
                  rounded
                  className="w-16 h-16 border-2 border-orange-400 rounded-full"
                  img="/images/user1.jpg"
                  alt="profile picture"
                /> */}
                <div className="flex items-center divide-x-2 divide-gray-400">
                  <cite className="pr-3 font-medium text-orange-400 text-xl">
                    Laxmi
                  </cite>
                  <cite className="pl-3 text-sm text-gray-200">
                    Ortho Diagnostics
                    <br />
                    Organiser L.E.A.D3
                  </cite>
                </div>
              </figcaption>
            </figure>
          </div>
          <div>
            <figure className="mx-auto max-w-screen-md text-center">
              <Blockquote>
                <p className="ttext-base md:text-lg font-medium italic text-gray-300">
                  "Thanks for sparing your valuable time and enlightening us
                  with many important aspects which will assist us to improve
                  our personal and professional lives."
                </p>
              </Blockquote>
              <figcaption className="mt-6 flex items-center justify-center space-x-3">
                {/* <Avatar
                  rounded
                  className="w-16 h-16 border-2 border-orange-400 rounded-full"
                  img="/images/user1.jpg"
                  alt="profile picture"
                /> */}
                <div className="flex items-center divide-x-2 divide-gray-400">
                  <cite className="pr-3 font-medium text-orange-400 text-xl">
                    Sachin Nene
                  </cite>
                  <cite className="pl-3 text-sm text-gray-200">
                    Head commercial,
                    <br />
                    Air Arabia
                  </cite>
                </div>
              </figcaption>
            </figure>
          </div>
          <div>
            <figure className="mx-auto max-w-screen-md text-center">
              <Blockquote>
                <p className="ttext-base md:text-lg font-medium italic text-gray-300">
                  "In my 20 years career, this happens to be one of the best
                  presentations I have ever come across."
                </p>
              </Blockquote>
              <figcaption className="mt-6 flex items-center justify-center space-x-3">
                {/* <Avatar
                  rounded
                  className="w-16 h-16 border-2 border-orange-400 rounded-full"
                  img="/images/user1.jpg"
                  alt="profile picture"
                /> */}
                <div className="flex items-center divide-x-2 divide-gray-400">
                  <cite className="pr-3 font-medium text-orange-400 text-xl">
                    Prasad Shetty
                  </cite>
                  <cite className="pl-3 text-sm text-gray-200">
                    Ortho Diagnostics
                    <br />
                    Organiser L.E.A.D3
                  </cite>
                </div>
              </figcaption>
            </figure>
          </div>
        </div>
      </div>
      <div className="flex flex-col items-center justify-center mt-10">
        <Link href="/testimonials">
          <p className=" hover:bg-orange-600 border border-orange-600 bg-orange-500 text-white hover:text-white font-semibold rounded-md uppercase px-6 py-1 lg:px-8 lg:py-2 text-sm w-56 text-center">
            More Testimonials{" "}
          </p>
        </Link>
      </div>
    </div>
  );
};

export default Testimonials;
