"use client";
import React, { useEffect, useState } from "react";
import { getLatestVideos } from "@/lib/mdxVideos";
import Link from "next/link";
import Image from "next/image";

const LatestVideos = () => {
  const [videos, setVideos] = useState([]);

  useEffect(() => {
    const fetchVideos = async () => {
      try {
        const fetchedVideos = await getLatestVideos();
        setVideos(fetchedVideos);
      } catch (error) {
        console.error("Failed to fetch the latest videos", error);
        setVideos([]);
      }
    };

    fetchVideos();
  }, []);

  if (videos.length === 0) {
    return <div>Loading...</div>;
  }

  return (
    <section className="bg-orange-100 py-2 md:py-4 lg:py-6 relative">
      <div className="max-w-screen-xl mx-5 lg:mx-auto">
        <div className="relative">
          <div
            className="font-extrabold text-orange-100 uppercase text-6xl lg:text-[12rem] relative -top-0 md:-top-10 z-10 text-center md:text-left"
            style={{ textShadow: "0px 0px 5px rgba(0,0,0,0.3)" }}
          >
            Rajiv Videos
          </div>
          <div className="absolute font-alternate text-orange-500 text-2xl md:text-3xl lg:text-6xl text-center top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 z-20 w-[80%] md:w-[60%] lg:w-[50%] whitespace-nowrap">
            Inspiring Videos on Leadership, Purpose & Life!
          </div>
        </div>
        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-5 items-start justify-between text-center">
          {videos.map((video) => (
            <div
              key={video.id}
              className="bg-white shadow-lg border-b-4 border-b-orange-600"
            >
              <div className="h-[15rem] w-full relative">
                <Image
                  src={video.featuredImage || "/images/video/default.jpg"}
                  alt={video.title}
                  fill
                  sizes="100vw"
                  className="absolute w-full h-full object-cover rounded-md pt-6"
                />
              </div>
              <h3 className="mt-4 text-2xl font-bold px-4">{video.title}</h3>
              <div>
                {" "}
                {new Date(video.date).toLocaleDateString("en-US", {
                  year: "numeric",
                  month: "long",
                  day: "numeric",
                })}
              </div>
              <div className="mt-2 md:mt-3 flex flex-col items-center justify-center">
                <Link href={`/video/${video.slug}`} passHref>
                  <p className="bg-white hover:bg-orange-600 border border-orange-600 text-orange-600 hover:text-white font-semibold rounded-md uppercase px-6 py-1 lg:px-8 lg:py-2 text-sm">
                    View Details
                  </p>
                </Link>
              </div>
            </div>
          ))}
        </div>
        <div className="flex flex-col items-center justify-center mt-10">
          <Link href="/video">
            <p className=" hover:bg-orange-600 border border-orange-600 bg-orange-500 text-white hover:text-white font-semibold rounded-md uppercase px-6 py-1 lg:px-8 lg:py-2 text-sm w-48 text-center">
              More Videos{" "}
            </p>
          </Link>
        </div>
      </div>
    </section>
  );
};

export default LatestVideos;
