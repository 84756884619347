"use client";
import React, { useEffect, useState } from "react";
import { getLatestBlogs } from "@/lib/mdxBlogs";
import Link from "next/link";
import Image from "next/image";

const LatestBlogs = () => {
  const [blogs, setBlogs] = useState([]);

  useEffect(() => {
    const fetchBlogs = async () => {
      try {
        const fetchedBlogs = await getLatestBlogs();
        setBlogs(fetchedBlogs);
      } catch (error) {
        console.error("Failed to fetch the latest blogs", error);
        setBlogs([]);
      }
    };

    fetchBlogs();
  }, []);

  if (blogs.length === 0) {
    return <div>Loading...</div>;
  }

  return (
    <section className="bg-orange-100/20 py-8 md:py-10 lg:py-12 relative">
      <div className="max-w-screen-xl mx-5 lg:mx-auto">
        <div>
          <div
            className="font-extrabold text-white uppercase text-6xl md:text-8xl lg:text-[12rem] relative -top-5 md:-top-10 text-center md:text-center  z-10"
            style={{ textShadow: "0px 0px 5px rgba(0,0,0,0.3)" }}
          >
            Rajiv Blog
          </div>
          <div className="font-alternate text-orange-500 text-2xl md:text-3xl lg:text-6xl text-center relative -top-16 md:-top-48 z-20">
            Inspiring Articles on Leadership Purpose & Life!
          </div>
        </div>
        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6 items-start justify-between text-center bg-white">
          {blogs.map((blog) => (
            <div
              key={blog.id}
              className="bg-white shadow-lg border-b-4 border-b-orange-500"
            >
              <div className="relative h-56 w-full">
                <Image
                  src={blog.featuredImage || "/images/blog/default.jpg"}
                  alt={blog.title}
                  fill
                  /* width={300}
                  height={200} */
                  className="w-full h-full object-cover rounded-md absolute"
                />
              </div>
              <h3 className="mt-4 text-xl font-bold h-12 px-4">{blog.title}</h3>
              <div className="mt-6 md:mt-8 flex flex-col items-center justify-center">
                <Link href={`/blog/${blog.slug}`} passHref>
                  <p className="bg-white hover:bg-orange-600 border border-orange-600 text-orange-600 hover:text-white font-semibold rounded-md uppercase px-8 py-3 lg:px-12 lg:py-4">
                    View Details
                  </p>
                </Link>
              </div>
            </div>
          ))}
        </div>
      </div>
      <div className="flex flex-col items-center justify-center mt-10">
        <Link href="/blog">
          <p className=" hover:bg-orange-600 border border-orange-600 bg-orange-500 text-white hover:text-white font-semibold rounded-md uppercase px-6 py-1 lg:px-8 lg:py-2 text-sm w-48 text-center">
            More Blogs{" "}
          </p>
        </Link>
      </div>
    </section>
  );
};

export default LatestBlogs;
